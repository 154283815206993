.header {
  position: relative;

  h1 {
    font-style: italic;
    margin: 0;

    img {
      max-width: 70%;
    }
  }

  h2 {
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    margin: 0;

    @media(max-width: 639px) {
      display: none;
    }

    img {
      max-width: 80%;
    }
  }
}

.secondaryOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
