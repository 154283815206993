.content {
  max-width: 90%;
  width: 800px;
  text-align: left;
  margin: 50px auto;
  border: 1px solid #6777bc;
  padding: 18px 24px;
  font-family: monospace;

  a {
    color: #6777bc;
  }

  p {
    margin-top: 0.15em;
  }

  h4 {
    margin-bottom: 0;
  }
}
