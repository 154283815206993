html, body {
  background-color: black;
}

:global {
  @import 'swiper/swiper.scss';

  .swiper-wrapper {
    height: auto;
  }
}

.carousel {
  max-width: 1200px;
  margin: 0 auto;
}

.sign {
  cursor: pointer;
}

.descriptionsContainer {
  width: 70%;
  margin: 0 auto 10px;
  overflow-x: hidden;
}

.descriptions {
  width: 400%;
}

.description {
  font-family: Arcade;
  font-size: 18px;
  text-transform: uppercase;
  width: 25%;
  float: left;
}

.hiddenDescription {
  visibility: hidden;
}

.clear {
  width: 0;
  height: 0;
  clear: both;
}

.cabinetLink {
  margin: 15px;
  display: inline-block;
  position: relative;
  cursor: pointer;

  @media(max-width: 1023px) {
    &.active, &:hover {
      .pressStart {
        visibility: visible;
      }
    }
  }

  &:hover {
    .pressStart {
      visibility: visible;
    }
  }

  &.media {
    .pressStart {
      top: 26%;
    }
  }

  &.arcade {
    .pressStart {
      top: 36%;
    }
  }

  &.shop {
    .pressStart {
      top: 30%;
    }
  }

  &.scores {
    .pressStart {
      top: 37%;
    }
  }

  &.help {
    .pressStart {
      top: 26%;
    }
  }

  .pressStart {
    visibility: hidden;
    font-family: Press Start;
    color: white;
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 50%;
  }
}
