.overlay {
  background-color: #000000aa;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.authenticate {
  width: 700px;
  margin: 100px auto;
  padding: 1em 2em 1.5em;
  background-color: black;
  color: white;
  text-align: center;
  border: 1px solid #6777bc;
  border-radius: 8px;
}

.button {
  font-size: 1em;
  border: 0;
  border-radius: 5px;
  padding: 7px 12px 7px 48px;
  margin: 0;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #8247e5;
    color: white;
  }

  &:active {
    background-color: darken(#8247e5, 20%);
  }
}

.maticTokenIcon {
  position: absolute;
  left: 8px;
  top: 5px;
  width: 32px;
}

.subtext {
  font-size: 0.5em;
  margin-top: 24px;
  height: 30px;

  a {
    color: #6777bc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
