.content {
    max-width: 90%;
    width: 800px;
    text-align: left;
    margin: 50px auto;
    border: 1px solid #6fed48;
    padding: 18px 24px;
    font-family: monospace;
  }
  
  .gameCrate {
    padding: 0;
    font-family: monospace;
    text-align: center;
    text-decoration: none;
    color: white;
  }
  
  .media {
    width: 100%;
  }
  
  .title {
    font-size: 30px;
    margin: 22px 10px 12px;
  }
  
  .price {
    background-color: #6777bc;
    border-radius: 12px;
    padding: 2px 8px;
    font-size: 20px;
    margin: 8px 0;
    display: inline-block;
  }
  
  .description {
    font-size: 20px;
    margin: 22px 10px;
  }
  
  .input {
    width: 200px;
    margin-bottom: 12px;
    padding: 6px 12px;
    font-size: 20px;
    text-align: center;
  }
  
  .button {
    width: 300px;
    padding: 6px 12px;
    font-size: 20px;
    margin: 0 0 12px 6px;
  }
  