.content {
  max-width: 90%;
  width: 800px;
  text-align: left;
  margin: 50px auto;
  border: 1px solid #d43024;
  padding: 18px 24px;
  font-family: monospace;
}

.youtubeContainer {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.youtube {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.image {
  position: relative;
  width: 100%;
}