.vrf {
  max-width: 90%;
  width: 500px;
  text-align: left;
  margin: 50px auto;
  border: 1px solid #aaaaaa;
  padding: 18px 24px;
}

.button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  font-size: 24px;
  margin-bottom: 12px;
}