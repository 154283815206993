.content {
  max-width: 90%;
  width: 800px;
  text-align: left;
  margin: 50px auto;
  border: 1px solid #5a72b3;
  padding: 18px 24px;
  font-family: monospace;

  a {
    color: #6777bc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
