a.gameCrate {
    border: 1px solid #888888;
    padding: 0 0 12px;
    font-family: monospace;
    text-align: center;
    text-decoration: none;
    color: white;
  
    &:hover {
      box-shadow: 0 0 8px 8px #6777bc;
    }
  }
  
  .media {
    width: 100%;
  }
  
  .title {
    font-size: 20px;
    margin: 22px 10px 12px;
  }
  
  .description {
    font-size: 14px;
    margin: 10px;
  }
  