.page {
  text-align: center;
  background-color: black;
  color: white;
  min-width: 100%;
  min-height: 100%;

  @media(min-width: 1024px) {
    background-image: url('../../../../assets/img/brick.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }

  .container {
    background-image: url('../../../../assets/img/background-shapes.png');
    background-repeat: repeat-x;
    background-position: bottom;
    padding-bottom: 50px;
    min-height: 100%;
    box-sizing: border-box;
  }

  footer {
    max-width: 90%;
    width: 1200px;
    text-align: center;
    margin: 50px auto;
    border: 1px solid #6777bc;
    padding: 18px 24px;
    font-family: monospace;
  }

  .links {
    text-align: center;
  }

  a {
    color: #6777bc;
  }
}
