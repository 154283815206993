.content {
  max-width: 90%;
  width: 800px;
  text-align: left;
  margin: 50px auto;
  border: 1px solid #d43024;
  padding: 18px 24px;
  font-family: monospace;
}

.title {

}

.description {

}

.poster {
  
}
