.page {
  text-align: center;
}

.title {
  margin-top: 1.5em;
}

.link {
  display: block;
  width: 100%;
  padding: 8px 16px;
  font-size: 24px;
  max-width: 90%;
  width: 500px;
  margin: 0 auto;
}
