.table {
  margin: 0 auto;
  border-collapse: collapse;
  color: white;
}

.table, .tableHeader, .tableCell {
  border: 1px solid #747474;
  padding: 8px;
  font-size: 18px;
}