.overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000000cc;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .creditCardCheckout {
    border: 0;
    padding: 1em 2em;
    background-color: white;
    max-width: 600px;
    color: black;
    box-shadow: 0px 0px 20px 5px white;
    border-radius: 20px;
  }
  
  .button {
    width: 300px;
    padding: 6px 12px;
    font-size: 20px;
    margin: 0 0 12px 6px;
  }
  