.polygonErrorMessage {
  font-size: 0.5em;
  margin-top: 24px;
  height: 30px;

  a {
    color: #6777bc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
