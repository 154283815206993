.table {
  margin: 0 auto;
  border-collapse: collapse;
  color: white;
  font-size: 1rem;
}

.table, .tableHeader, .tableCell {
  border: 1px solid black;
  padding: 8px;
}
