.content {
  max-width: 90%;
  width: 800px;
  text-align: left;
  margin: 50px auto;
  border: 1px solid #a8b35a;
  padding: 18px 24px;
  font-family: monospace;

  .title {
    text-align: center;
    font-size: 1.5em;
    margin: 0.25em auto;
  }

  .mintNumber {
    text-align: center;
    font-size: 0.75em;
    margin: 0.25em auto;
  }

  .description {
    text-align: center;
    font-size: 1em;
    margin: 0.25em auto;
  }

  .opensea {
    text-align: center;
  }

  a {
    color: #6777bc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.media {
  width: 100%;
}
