.content {
  max-width: 90%;
  width: 1200px;
  text-align: left;
  margin: 50px auto;
  border: 1px solid #cfd0ca;
  padding: 18px 24px;
  font-family: monospace;

  h2 {
    text-align: center;
  }

  h3 {
    text-align: center;
  }

  a {
    color: #6777bc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.media {
  width: 100%;
}

.scoreArea {
  display: flex;
  margin-top: 40px;
}

.left {
  flex: 1;
  max-width: 300px;
}

.right {
  flex: 4;
}

.tabs {
  display: grid;
}

.loading {
  text-align: center;
}