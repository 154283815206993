.content {
  max-width: 90%;
  width: 800px;
  text-align: left;
  margin: 50px auto;
  border: 1px solid #a8b35a;
  padding: 18px 24px;
  font-family: monospace;

  a {
    color: #6777bc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.media {
  width: 100%;
}

.quantity {
  text-align: center;
}

.button {
  display: block;
  width: 50%;
  margin: 0 auto;
  padding: 8px 16px;
  font-size: 24px;
  margin-bottom: 12px;
}

.helpText {
  text-align: center;
  width: 80%;
  margin: 12px auto;
}

.warningText {
  text-align: center;
  font-weight: bold;
  background-color: #ff8800;
  color: #ffffff;
  border-radius: 12px;
  margin: 0 auto;
  width: 80%;
  padding: 12px;
}
