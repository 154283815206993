.form {
  max-width: 90%;
  width: 500px;
  text-align: left;
  margin: 16px auto 0 auto;
  border: 1px solid #aaaaaa;
  padding: 18px 24px;
}

.label {
  display: block;
  margin-bottom: 4px;
}

.input {
  display: block;
  width: 100%;
  margin-bottom: 12px;
  padding: 8px 16px;
  font-size: 24px;
}

.button {
  display: block;
  width: 100%;
  max-width: 400px;
  padding: 8px 16px;
  font-size: 24px;
  font-weight: bold;
  margin: 24px auto 12px;
  box-shadow: inset 0px 1px 0px 0px #e184f3;
  background: linear-gradient(to bottom, #c123de 5%, #a20dbd 100%);
  background-color: #c123de;
  border-radius: 6px;
  border: 1px solid #a511c0;
  color: #ffffff;
  text-shadow: 0px 1px 0px #9b14b3;

  &:hover {
    background: linear-gradient(to bottom, #a20dbd 5%, #c123de 100%);
    background-color: #a20dbd;
  }

  &:active {
    position:relative;
    top:1px;
  }
}

.error {
  background-color: #ffd2cc;
  padding: 8px 16px;
  margin: 12px 0 0;
}
