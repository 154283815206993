@font-face {
  font-family: 'Press Start';
  src: url('../../assets/font/Press_Start_2P/PressStart2P-Regular.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Arcade';
  src: url('../../assets/font/Arcade_Ya/ARCADE_N.TTF');
  font-weight: 600;
  font-style: normal;
}

html, body {
  border: 0;
  padding: 0;
  margin: 0;
}

.app {
  font-family: Arial;
  font-size: 24px;
}
