html {
  body {
    a.tab {
      font-size: 1rem;
      color: #000000;
      background-color: #ffffff;
      border: 1px solid #000000;
      padding: 0.25rem 1rem;
      text-align: center;
      cursor: pointer;
      text-decoration: none;

      &:not(:first-of-type) {
        border-left: none;
      }

      &.active, &:hover {
        background-color: #b1bbe9;
        text-decoration: none;
      }

      &:active {
        background-color: #6777bc;
      }

      &:first-of-type {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }

      &:last-of-type {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
  }
}